import React, { FC, useEffect, useContext } from 'react'
import styled from 'styled-components'
import { NavigationContext } from '@/contexts/NavigationContext'
import { SanityPost } from 'web/graphql-types'
import ModuleLoop from '@/containers/ModulesLoop'
import { Box } from '@material-ui/core'

const StyledBox = styled(Box)``

interface PostProps {
  post: SanityPost
}

const PostContainer: FC<PostProps> = ({
  post: { title, meta, contentModules }
}) => {
  const { setRoute, setBackButton } = useContext(NavigationContext)
  useEffect(() => {
    setBackButton(true)
    setRoute('/blog')
  }, [])

  return (
    <StyledBox>
      <ModuleLoop modules={contentModules?.modules} />
    </StyledBox>
  )
}

export default PostContainer
