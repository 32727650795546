import React, { FC } from 'react'
import { graphql } from 'gatsby'
import { SanityPost } from 'web/graphql-types'
import Layout from '@/containers/Layout'
import PostContainer from '@/containers/PostContainer'

interface PostProps {
  data: {
    post: SanityPost
  }
}

const Post: FC<PostProps> = ({ data: { post: sanityPost } }) => {
  const { lightBackground } = sanityPost
  return (
    <Layout meta={sanityPost.meta} useLightBackground={lightBackground ?? lightBackground}>
      <PostContainer post={sanityPost}></PostContainer>
    </Layout>
  )
}

export default Post

export const query = graphql`
  query($id: String!) {
    post: sanityPost(id: { eq: $id }) {
      title
      meta {
        ...metaData
      }
      lightBackground
      contentModules {
        ...contentModulesData
      }
    }
  }
`
